// ProposalsList.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ProposalsList.css'; // Import your CSS file for styles

const BASE_URL = 'https://phoenix-lcd.terra.dev/cosmwasm/wasm/v1/contract/terra1ut05tzut8glnpqpnm304mkns0gq3j5crsdkzlpnmgw4uhh966qus37xjkg/smart/';

const ProposalsList = () => {
  const [proposals, setProposals] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [decodedMessage, setDecodedMessage] = useState('');
  const [contractinproposal, setContractinproposal] = useState('');
  const proposalsPerPage = 10;
  useEffect(() => {
    const fetchProposals = async () => {
      try {
        const payloadBase64 = btoa(JSON.stringify({ reverse_proposals: {} }));
        const response = await axios.get(`${BASE_URL}${payloadBase64}`);
        setProposals(response.data.data.proposals);
      } catch (error) {
        console.error('Error fetching proposals:', error);
      }
    };

    fetchProposals();
  }, []);

  const getProposalDetails = async (proposalId) => {
    try {
      const payloadBase64 = btoa(JSON.stringify({ proposal: { proposal_id: proposalId } }));
      const response = await axios.get(`${BASE_URL}${payloadBase64}`);
      const proposalDetails = response.data.data;
      // Decode base64 message
      const base64Message = proposalDetails.msgs[0]?.wasm?.execute?.msg;
      const decodedMessageValue = base64Message ? atob(base64Message) : '';
      const contractinproposalValue = proposalDetails.msgs[0]?.wasm?.execute?.contract_addr;

      setDecodedMessage(decodedMessageValue);
      setContractinproposal(contractinproposalValue);

      console.log(proposalDetails);
    } catch (error) {
      console.error('Error fetching proposal details:', error);
    }
  };

  const indexOfLastProposal = currentPage * proposalsPerPage;
  const indexOfFirstProposal = indexOfLastProposal - proposalsPerPage;
  const currentProposals = proposals.slice(indexOfFirstProposal, indexOfLastProposal);

  const renderProposals = currentProposals.map((proposal) => (
    <tr key={proposal.id}>
      <td>{proposal.id}</td>
      <td>{proposal.title}</td>
      <td>{proposal.description}</td>
      <td>{proposal.status}</td>
      <td>{proposal.expires.at_time}</td>
      <td>
        <button onClick={() => getProposalDetails(proposal.id)}>
          View Details
        </button>
      </td>
    </tr>
  ));

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <h2>Proposals List</h2>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th>Description</th>
            <th>Status</th>
            <th>Expires At</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          {renderProposals}
        </tbody>
      </table>
      <div>
        <ul className="pagination">
          {Array.from({ length: Math.ceil(proposals.length / proposalsPerPage) }, (_, index) => (
            <li key={index + 1} className={currentPage === index + 1 ? 'active' : ''} onClick={() => paginate(index + 1)}>
              {index + 1}
            </li>
          ))}
        </ul>
      </div>

      {/* Display decoded message and contract in proposal */}
      {decodedMessage && (
        <div>
          <h3>Decoded Message:</h3>
          <p>{decodedMessage}</p>
        </div>
      )}
      {contractinproposal && (
        <div>
          <h3>Contract in Proposal:</h3>
          <p>{contractinproposal}</p>
        </div>
      )}
    </div>
  );
};

export default ProposalsList;